import React from "react";
import logo from "../assets/apes.png";
import "../App.css";

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner">
      <img src={logo} alt="Loading" className="spinner-logo" />
    </div>
  );
};

export default LoadingSpinner;
