import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/apes.png";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-white border border-b-2">
      <div className="container mx-auto px-4 py-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Link to="/">
              <img className="h-20" src={logo} alt="Anointed PES Logo" />
            </Link>
            <Link to="/" className="text-xl font-semibold text-red-700 ml-2">
              {" "}
              Anointed Provision and Education Services
            </Link>
          </div>
          <div className="lg:hidden">
            <button
              className="text-gray-600 focus:outline-none"
              onClick={toggleMenu}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
                ></path>
              </svg>
            </button>
          </div>
          <div className={`hidden lg:flex items-center space-x-6`}>
            <Link to="/" className="text-gray-600 hover:text-red-700">
              {" "}
              Home
            </Link>
            <Link to="/about" className="text-gray-600 hover:text-red-700">
              {" "}
              About Us
            </Link>
            <Link to="/services" className="text-gray-600 hover:text-red-700">
              {" "}
              Services
            </Link>
            <Link to="/contact" className="text-gray-600 hover:text-red-700">
              {" "}
              Contact
            </Link>
            <Link to="/apply" className="text-gray-600 hover:text-red-700">
              {" "}
              Apply Now
            </Link>
          </div>
        </div>
      </div>
      <div className={`lg:hidden mt-4  ${isOpen ? "block" : "hidden"}`}>
        <Link
          to="/"
          className="bg-[#F9F9F9] block text-gray-600 hover:text-red-700 py-3 px-4 border-b border-gray-300 border-t"
        >
          Home
        </Link>
        <Link
          to="/about"
          className="bg-[#F9F9F9] block text-gray-600 hover:text-red-700 py-3 px-4 border-b border-gray-300"
        >
          About Us
        </Link>
        <Link
          to="/services"
          className="bg-[#F9F9F9] block text-gray-600 hover:text-red-700 py-3 px-4 border-b border-gray-300"
        >
          Services
        </Link>
        <Link
          to="/contact"
          className="bg-[#F9F9F9] block text-gray-600 hover:text-red-700 py-3 px-4 border-b border-gray-300"
        >
          Contact
        </Link>
        <Link
          to="/apply"
          className="bg-[#F9F9F9] block text-gray-600 hover:text-red-700 py-3 px-4 border-b border-gray-300"
        >
          Apply Now
        </Link>
      </div>
    </nav>
  );
};

export default NavBar;
