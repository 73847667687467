import React, { useState, useEffect } from "react";
import NavBar from "../Components/NavBar";
import HeroSection from "../Components/HeroSection";
import ChooseUs from "../Components/ChooseUs";
import ScholarshipSection from "../Components/ScholarshipSection";
import PartnerSection from "../Components/Partner";
import Footer from "../Components/Footer";
import LoadingSpinner from "../Components/LoadingSpinner";

const Home = () => {
  const [loading, setLoading] = useState(
    localStorage.getItem("visitedBefore") ? false : true
  );

  useEffect(() => {
    localStorage.setItem("visitedBefore", true);

    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="font-interRegular">
      {loading ? (
        <div
          className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-white z-50"
          aria-busy="true"
          aria-live="polite"
          aria-label="Loading content"
        >
          <LoadingSpinner />
          <p className="text-gray-600 mt-4">Loading...</p>
        </div>
      ) : (
        <div className="flex flex-col">
          <NavBar />
          <HeroSection className="flex-grow" />
          <ChooseUs />
          <ScholarshipSection />
          <PartnerSection />
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Home;
