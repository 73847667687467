import React from "react";
import { Link } from "react-router-dom";
import { FaRegEnvelope } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";

const Footer = () => {
  return (
    <footer>
      <hr className="border-red-700 border-2 w-full mt-8" />

      <div className="text-gray-900 bg-white py-8 px-2">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="text-left md:text-center">
              {" "}
              {/* Align content to the left on all screens, center on desktop */}
              <h4 className="text-xl font-interBold mb-4">Useful Links</h4>
              <ul className="space-y-2 font-interSemiBold">
                <li>
                  <Link to="/" className="">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/services" className="">
                    Services
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <div className="text-left md:text-center">
              {" "}
              {/* Align content to the left on all screens, center on desktop */}
              <h4 className="text-xl font-interBold mb-4">Contact Us</h4>
              <ul className="space-y-2 ">
                <li className="flex space-x-2 items-center md:pl-36">
                  <FaPhone size={24} />
                  <span>+237 6 78 22 45 89</span>
                </li>
                <li className="flex space-x-2 items-center md:pl-36">
                  <FaPhone size={24} />
                  <span> +90 533 858 23 40</span>
                </li>
                <li className="flex space-x-2 items-center md:pl-24">
                  <FaRegEnvelope size={24} />
                  <span>anpeducationservices@gmail.com</span>
                </li>
              </ul>
            </div>
            <div className="text-left md:text-center">
              {" "}
              {/* Align content to the left on all screens, center on desktop */}
              <h4 className="text-xl font-interBold mb-4">Visit Us</h4>
              <ul className="space-y-2">
                <li>Biscuiterie, Biyem Assi, Yaoundé, Cameroon</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p className="text-center bg-[#3A3A3A] text-white py-8">
          Copyright © 2024 Anointed Provision and Education Services
        </p>
      </div>
    </footer>
  );
};

export default Footer;
