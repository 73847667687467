import React from "react";
import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";

const About = () => {
  return (
    <main>
      <NavBar />
      <section className="py-12">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <h2 className="text-3xl font-bold mb-8">About Us</h2>
            <p className="mb-6">
              At APES, our mission is simple: we believe in the power of
              education to transform lives. Our goal is to provide formal
              education that not only imparts knowledge but also changes
              perceptions, behaviors, and helps individuals realize their
              dreams.
            </p>
            <p className="mb-6">
              Our vision is to promote literacy through formal education and
              make higher education accessible to all. We aim to facilitate
              study opportunities in international and multicultural
              universities while also contributing to combating famine through
              sustainable plantation agriculture in Africa.
            </p>
            <p className="mb-6">
              Founded in 2016 by Wikodih Magah Edwin, Abi Njoh Roger, and Joel
              Menffih Magah, APES started with a simple idea: to promote
              education, mobility, and meet basic needs in communities. Since
              then, we have expanded our services to include provision stores
              and plantation farming, all aimed at making a positive impact on
              people's lives.
            </p>
            <p className="mb-6">Our core values drive everything we do:</p>
            <ul className="list-disc ml-8 mb-6">
              <li>
                Competence: Our team of travelling agents and staff are highly
                skilled and knowledgeable in their respective fields.
              </li>
              <li>
                Trust: We value the trust placed in us by our clients and
                partners, and we strive to uphold it in all our interactions.
              </li>
              <li>
                Consultation: Our business and travelling consultants are here
                to provide expert advice and guidance to ensure the success of
                your ventures.
              </li>
              <li>
                Partnership: We believe in building fruitful partnerships that
                enable us to achieve greater impact together.
              </li>
            </ul>
            <p>
              Meet our dedicated team: Wikodih Magah Edwin, Abi Njoh Roger, Joel
              Menffih Magah, Mbi Ayuk Mathias, and Ticha Claudius.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default About;
