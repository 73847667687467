import React from "react";
import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";
import { IoDocument } from "react-icons/io5";
import { PiCertificateFill } from "react-icons/pi";
import { MdOutlinePriceCheck } from "react-icons/md";
import { FaMicrophoneAlt } from "react-icons/fa";
import { MdOutlineTipsAndUpdates } from "react-icons/md";
import { RiCustomerService2Line } from "react-icons/ri";

const Services = () => {
  return (
    <div>
      <NavBar />
      <div className="bg-white text-black py-6 px-6">
        <div className="container mx-auto mt-2">
          <h2 className="text-3xl md:text-4xl font-bold mb-6 text-center">
            Services
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Certification Section */}
          <div className="flex flex-col md:flex-row items-center justify-center rounded-lg p-6 md:p-8 text-center md:text-left space-x-2">
            <div className="rounded-full p-4 mb-6 bg-white border-2 border-red-700">
              <PiCertificateFill size={48} color="red" />
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">School Admissions</h3>
              <p className="text-lg">
                Guaranteed admission to your dream school with unbeatable
                opportunities and affordability.
              </p>
            </div>
          </div>
          {/* Experience Section */}
          <div className="flex flex-col md:flex-row items-center justify-center rounded-lg p-6 md:p-8 text-center md:text-left space-x-2">
            <div className="rounded-full p-4 mb-6 bg-white border-2 border-red-700">
              <IoDocument size={48} color="red" />
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">Residence Permit</h3>
              <p className="text-lg">
                We help students with residence permit processing to avoid
                immigration problem
              </p>
            </div>
          </div>
          {/* Affordability Section */}
          <div className="flex flex-col md:flex-row items-center justify-center rounded-lg p-6 md:p-8 text-center md:text-left space-x-2">
            <div className="rounded-full p-4 mb-6 bg-white border-2 border-red-700">
              <MdOutlinePriceCheck size={48} color="red" />
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">Affordability</h3>
              <p className="text-lg">
                We choose the best schools, Scholarship plans, tuition fees,
                flights, Accommodation.
              </p>
            </div>
          </div>
          {/* Seminars and Talks Section */}
          <div className="flex flex-col md:flex-row items-center justify-center rounded-lg p-6 md:p-8 text-center md:text-left space-x-2">
            <div className="rounded-full p-4 mb-6 bg-white border-2 border-red-700">
              <FaMicrophoneAlt size={48} color="red" />
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">Seminars and Talks</h3>
              <p className="text-lg">
                We organize seminars and talks to provide valuable insights and
                guidance to students.
              </p>
            </div>
          </div>
          {/* Scholarship Exams Section */}
          <div className="flex flex-col md:flex-row items-center justify-center rounded-lg p-6 md:p-8 text-center md:text-left space-x-2">
            <div className="rounded-full p-4 mb-6 bg-white border-2 border-red-700">
              <MdOutlineTipsAndUpdates size={48} color="red" />
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">Scholarship Exams</h3>
              <p className="text-lg">
                We arrange scholarship exams to help students secure funding for
                their education.
              </p>
            </div>
          </div>
          {/* Customer Experience Section */}
          <div className="flex flex-col md:flex-row items-center justify-center rounded-lg p-6 md:p-8 text-center md:text-left space-x-2">
            <div className="rounded-full p-4 mb-6 bg-white border-2 border-red-700">
              <RiCustomerService2Line size={48} color="red" />
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">
                Customer Experience
              </h3>
              <p className="text-lg">
                We provide personalized support and guidance to our customers
                every step of the way.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Services;
