import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";

const Contact = () => {
  const form = useRef();
  const [messageSent, setMessageSent] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [nameError, setNameError] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    const name = form.current.user_name.value.trim();
    const email = form.current.user_email.value.trim();
    const phone = form.current.phone_number.value.trim();

    if (!name || !email) {
      setNameError(true);
      setEmailError(false);
      setPhoneError(false);
      return;
    } else {
      setNameError(false);
    }

    if (!validateEmail(email)) {
      setEmailError(true);
      return;
    } else {
      setEmailError(false);
    }

    if (!validatePhoneNumber(phone)) {
      setPhoneError(true);
      return;
    } else {
      setPhoneError(false);
    }

    emailjs
      .sendForm("service_lcqppk5", "template_5ty9f29", form.current, {
        publicKey: "YL0NCtC3Q1sSWZ6Kj",
      })
      .then(
        () => {
          setMessageSent(true);
          form.current.reset();
          setTimeout(() => setMessageSent(false), 5000);
          setEmailError(false);
          setPhoneError(false);
          setNameError(false);
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validatePhoneNumber = (phone) => {
    const phonePattern = /^\+[0-9]+$/;
    return phonePattern.test(phone);
  };

  return (
    <section>
      <NavBar />
      <div className="container mx-auto px-4 py-8">
        <h2 className="text-2xl font-bold mb-4">Contact Us</h2>

        <form className="space-y-4" ref={form} onSubmit={sendEmail}>
          <div>
            <label className="block text-sm font-semibold" htmlFor="name">
              Your Name (required)
            </label>
            <input
              className={`w-full border ${
                nameError ? "border-red-500" : "border-gray-300"
              } rounded-md px-3 py-2`}
              type="text"
              id="name"
              name="user_name"
              required
            />
            {nameError && (
              <p className="text-red-500 text-xs mt-1">
                Please enter your name.
              </p>
            )}
          </div>
          <div>
            <label className="block text-sm font-semibold" htmlFor="email">
              Your Email (required)
            </label>
            <input
              className={`w-full border ${
                emailError ? "border-red-500" : "border-gray-300"
              } rounded-md px-3 py-2`}
              type="email"
              id="email"
              name="user_email"
              required
            />
            {emailError && (
              <p className="text-red-500 text-xs mt-1">
                Please enter a valid email address.
              </p>
            )}
          </div>
          <div>
            <label className="block text-sm font-semibold" htmlFor="phone">
              Phone Number (required, start with + and contain only numbers)
            </label>
            <input
              className={`w-full border ${
                phoneError ? "border-red-500" : "border-gray-300"
              } rounded-md px-3 py-2`}
              type="tel"
              id="phone"
              name="phone_number"
              pattern="^\+[0-9]+$"
              required
            />
            {phoneError && (
              <p className="text-red-500 text-xs mt-1">
                Please enter a valid phone number starting with + and containing
                only numbers.
              </p>
            )}
          </div>
          <div>
            <label className="block text-sm font-semibold" htmlFor="subject">
              Subject
            </label>
            <input
              className="w-full border border-gray-300 rounded-md px-3 py-2"
              type="text"
              id="subject"
              name="subject"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold" htmlFor="message">
              Your Message
            </label>
            <textarea
              className="w-full border border-gray-300 rounded-md px-3 py-2"
              id="message"
              name="message"
              rows="5"
            ></textarea>
          </div>
          <button
            className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition duration-300"
            type="submit"
          >
            Submit
          </button>
        </form>

        {messageSent && (
          <div
            className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mt-4"
            role="alert"
          >
            <strong className="font-bold">Success!</strong>
            <span className="block sm:inline">
              {" "}
              Your message has been sent successfully.
            </span>
            <span
              className="absolute top-0 bottom-0 right-0 px-4 py-3"
              onClick={() => setMessageSent(false)}
            >
              <svg
                className="fill-current h-6 w-6 text-green-500"
                role="button"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <title>Close</title>
                <path
                  fillRule="evenodd"
                  d="M14.293 5.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414L10 8.586l4.293-4.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </div>
        )}
      </div>
      <Footer />
    </section>
  );
};

export default Contact;
