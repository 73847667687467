import React from "react";
import { Link } from "react-router-dom";

const ScholarshipSection = () => {
  return (
    <div className="bg-red-800 py-6 md:py-16 text-white text-center">
      <div className="max-w-5xl mx-auto">
        <h2 className="text-2xl md:text-3xl font-bold mb-6">
          Scholarships Available
        </h2>
        <div className="flex flex-wrap justify-center mx-2 space-x-4">
          <ScholarshipOption text="Full Scholarship" />
          <ScholarshipOption text="75% Scholarship" />
          <ScholarshipOption text="50% Scholarship" />
          <ScholarshipOption text="25% Scholarship" />
        </div>
        <Link to="/apply">
          <button className="bg-white text-red-800 font-semibold py-3 px-6 rounded-full mt-8 hover:bg-red-600 hover:text-white transition duration-300">
            Start Your Application
          </button>
        </Link>
      </div>
    </div>
  );
};

const ScholarshipOption = ({ text }) => {
  return (
    <div className="flex items-center mb-6 md:mb-0 md:px-2 space-x-2">
      <div className="flex items-center justify-center bg-red-700 rounded-full w-8 h-8 md:w-10 md:h-10 mb-2 md:mr-2">
        <span className="text-white text-sm md:text-lg">&#10003;</span>
      </div>
      <p className="md:text-lg text-sm">{text}</p>
    </div>
  );
};

export default ScholarshipSection;
