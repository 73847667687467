import React from "react";
import { Link } from "react-router-dom";
import gau from "../assets/uni-pictures/girne-american-university-clipart.jpg";
import neu from "../assets/uni-pictures/Near_East_University.svg.png";
import finale from "../assets/uni-pictures/finale_logo.png";
import emu from "../assets/uni-pictures/EMU_Cyprus.svg.png";
import miu from "../assets/uni-pictures/miu.png";
import igu from "../assets/uni-pictures/igu.png";

const PartnerSection = () => {
  return (
    <section>
      <div className="bg-white py-12 md:py-24 text-red-600">
        <div className="container mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">
            Our Partners
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-12">
            <PartnerCard
              image={neu}
              name="Near East University"
              videoUrl="https://www.youtube.com/embed/c0auZbBHjpI"
            />
            <PartnerCard
              image={gau}
              name="Girne American University"
              videoUrl="https://www.youtube.com/embed/bSXPPW1Nqik?si=xvEBCksZSDTTtBs0"
            />
            <PartnerCard
              image={finale}
              name="Finale University"
              videoUrl="https://www.youtube.com/embed/dwVuqkGdBYU?si=mNe_w_vDViPOXdD0"
            />
            <PartnerCard
              image={emu}
              name="Eastern Mediterranean University"
              videoUrl="https://www.youtube.com/embed/uuOL_mPvbcU?si=kbSnAOGpM1HYybQJ&amp;start=3"
            />
            <PartnerCard
              image={miu}
              name="Istanbul Medipol University"
              videoUrl="https://www.youtube.com/embed/oui58yM0ABw?si=eZ40FUnvvHlJI6MP"
            />
            <PartnerCard
              image={igu}
              name="Istanbul Gelisim University"
              videoUrl="https://www.youtube.com/embed/17G0edemcmM?si=NQzRsCorW24tVmvu"
            />
          </div>
        </div>
      </div>
      <div className="bg-black text-white py-16">
        <div className="container mx-auto flex items-center justify-center flex-col lg:flex-row md:space-x-24">
          <div className="text-center lg:text-left mb-8 lg:mb-0 lg:mr-8">
            <h2 className="text-3xl mb-4 font-interSemiBold">
              Do you Need help or assistance?
            </h2>
            <h4 className="text-sm mb-4">We are ready to help</h4>
          </div>
          <Link
            to="/contact"
            className="inline-block bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition duration-300"
          >
            Choose Us
          </Link>
        </div>
      </div>
    </section>
  );
};

const PartnerCard = ({ image, name, videoUrl }) => {
  return (
    <div>
      <div className="md:w-[32vw] mx-auto overflow-hidden">
        <div className="p-4">
          <div className="flex justify-center items-center mb-4">
            <img src={image} alt={name} className="h-24 object-contain" />
          </div>
          <h3 className="text-xl font-bold mb-4 text-center">{name}</h3>
          <div className="w-full aspect-w-24 aspect-h-9 h-[24rem]">
            <iframe
              className="w-full h-full"
              src={videoUrl}
              title={name}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerSection;
