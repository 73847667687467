import React from "react";
import { Link } from "react-router-dom";

const HeroSection = () => {
  return (
    <div className="bg-hero py-24 px-4 text-gray-200 text-center">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl md:text-5xl font-bold mb-6">
          Want to study in the Best Schools in the World?
        </h1>
        <p className="text-lg md:text-xl mb-8">
          Travel, work and study abroad with 25% to 100% Scholarship.
        </p>
        <p className="text-lg md:text-xl mb-8">
          We work with over 20 Universities in 5 countries and leverage on this
          to give our clients and students the best opportunity to study abroad.
        </p>
        <Link to="/apply">
          <button className="bg-white text-red-600 font-semibold py-3 px-6 rounded-full hover:bg-red-700 hover:text-white transition duration-300">
            TAKE THE FIRST STEP
          </button>
        </Link>
      </div>
    </div>
  );
};

export default HeroSection;
