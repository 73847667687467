import React from "react";
import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";
import FormfacadeEmbed from "@formfacade/embed-react";

const FORMFACADE_URL =
  "https://formfacade.com/include/115631366271290876130/form/1FAIpQLSedV6MDFvIgS7c_Q_x1u1lNmB21CNblG4BggXWLuSCiSpS4XA/classic.js/?div=ff-compose";

const Apply = () => {
  const prefillForm = () => {
    // Optional: Refer to the provided link to find the entry IDs for prefilling input fields:
    // https://formfacade.com/website/does-formfacade-support-pre-filled-survey-links-like-native-google-forms-on-1FAIpQLSfGvg22V7Lzyw_5AEbKBSpklS_TMw6tKxcQiDqlC9KvfBVTgQ.html

    return {
      "entry.1297600622": "@formfacade/embed-react",
      "entry.813617742": `${new Date()}`,
    };
  };

  const onSubmitForm = () => {
    // Add your specific form submission handling code below.
    console.log("----FORM SUBMITTED----");
  };

  return (
    <main>
      <NavBar />
      <FormfacadeEmbed
        formFacadeURL={FORMFACADE_URL}
        onSubmitForm={onSubmitForm}
        // Optional: Use prefillForm to prefill form fields. See prefillForm function for details. Remove if not required.
        prefillForm={prefillForm}
      />

      <Footer />
    </main>
  );
};

export default Apply;
